<template>
  <v-layout row wrap max-width-1400 full-width ma-auto text-xs-center py-5 mb-4>
    <v-flex xs12>
      <v-img
        contain
        max-width="280px"
        width="100%"
        position="center center"
        class="ma-auto"
        :src="image"
        :lazy-src="image"
      ></v-img>

      <p class="graphik-light mb-0" :class="{'font-22': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_house_price_description_1') }} <span class="primary--text graphik-bold" :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"> $ {{ 1499 }}</span> {{ $ml.get('content_house_price_description_2') }}</p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentContactForQuoting',
  data () {
    return {
      image: require('@/assets/img/companies/chubbhogar-center.png')
    }
  }
}
</script>

<style scoped>
</style>
